
import { Component, Vue } from 'vue-property-decorator'
import { CultureList } from '@/types/school'
import { FileInfo } from '../../../types/common'

@Component({
  filters: {
    imgListFormat (list: Array<FileInfo>) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Detail extends Vue {
  private info: CultureList = {
    projectId: '',
    projectName: '',
    name: '',
    content: '',
    startDate: '',
    endDate: '',
    fileList: []
  }

  get scienceId () {
    return this.$route.params.id as string
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.school.selectCampusScienceByScienceId, {
      scienceId: this.scienceId
    }).then(res => {
      this.info = res || {}
    })
  }
}
